import { getTrackingConfig } from "@/features/branding";
import supabase from "@/features/supabase";
import { store } from "@/store";
import { internalApi } from "@/store/services/internal";
interface ToolUsageData {
  type: string;
  count: number;
}

// TODO: Replace with common getOrgs function when exists.
const getOrgs = async () => {
  const {
    data
  } = await supabase.from("orgs").select("*");
  return data;
};
export default async function logToolUsage(data: ToolUsageData) {
  const {
    hubspot
  } = getTrackingConfig();
  const {
    data: {
      user
    }
  } = await supabase.auth.getUser();
  if (!hubspot.enabled || !hubspot.allowToolTracking) {
    return;
  }

  // Determine what organisation we will use.
  const organisationList = await getOrgs();
  const organisation = organisationList?.length ? organisationList[0].name || organisationList[0].id : "<no_org>";

  // Send information to Hubspot form.
  try {
    const action = internalApi.endpoints.logProductUsage.initiate({
      organisation,
      product: data.type,
      username: user?.email || "",
      amount: data.count,
      pipelineId: hubspot.pipeline.id,
      pipelineStage: hubspot.pipeline.stage,
      domain: window.location.hostname
    });
    await store.dispatch(action);
  } catch (err) {
    console.log("Failed to send tracking information to HubSpot:", err);
  }
}