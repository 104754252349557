import { getRiskBandsMode } from "@/features/branding";

/** Example structure of a .csv which can be uploaded to Portfolio Export. */
export const buildPortfolioExportExampleCsv = () => {
  const archetype = {
    abc: "Apartment",
    halo: "Commercial Skyscraper",
    simple: "High Rise Structure",
  }[getRiskBandsMode()];

  return [
    [
      "ID (optional)",
      "Address (geocoded to provide Latitude/Longitude if they are not supplied)",
      "Latitude (filled in via address if not supplied)",
      "Longitude (filled in via address if not supplied)",
      "Archetype (optional)",
      "Build Year (optional)",
      "Floor height above ground (metres, optional)",
    ],
    ["address", "8 Finance Street, Central", undefined, undefined, archetype, undefined, 0],
    ["coords", undefined, 22.2855368, 114.1592, undefined, 1980, 0],
    ["coords-override-address", "8 Finance Street, Central", 22.2855368, 114.1592, archetype, 1980, undefined],
  ];
};

export const portfolioExportSize = {
  1000: "1000",
  20000: "20k",
  55000: "55k",
  100000: "100k",
  120000: "100k+",
};

export type PortfolioExportSizeType = keyof typeof portfolioExportSize;
